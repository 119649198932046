import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, user, } from 'ufinet-web-functions'
import { colsCompletedAuthorizationSys } from './CompletedColData'
import { UseMutationDataAuthCompleted, UseDownloadFileAuthorizatorCompletedSys } from 'request'
import { useTranslation } from 'utils/translation/Translation'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement } from 'types/commisions/CommisionElementTypes'
import { faDownload, faUpload, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { CounterContext } from 'context/CounterContext'
import { AuthorizationStatus, ErrorResponse } from 'types/typesAndValues'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { FiltersHeaders } from '../filterHeaders/FiltersHeaders'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'
import { useFiltersAuthorizationSys } from 'hooks/useFiltersAuthorizationSys'


const CompletedAuthorizationSysPage = () => {
    const intl = useIntl()
    const translate = useTranslation()

    const colsRejectedPage = useMemo(() => colsCompletedAuthorizationSys(intl), [intl])



    const authData = useContext(AuthContext)
    const { refetchData } = useContext(CounterContext)
    const userEmail = authData.userData?.username
    const roles = authData.userData?.authorities || []
    const permissions = Authority.getCommissionAuthorizationSysPermissions(roles)


    const [selectedValues, setSelectedValues] = useState<CommissionElement[]>([])
    const [paging, setPaging] = useState<any>()
    const [filterData, setFilterData] = useState<any>({})

    const isSelected = selectedValues && selectedValues.length > 0



    const cbSuccessDataAuthCompleted = ({ data }: any) => {

    }

    const cbSuccessDownloadFileCompleted = ({ data }: any) => {

    }


    const { mutate: fnDataCompleted, isLoading: isLoadingAuthDataCompleted, data: dataAuthCompleted } = UseMutationDataAuthCompleted(cbSuccessDataAuthCompleted)
    const { mutate: downloadFile, isLoading: isLoadingDownloadFile, isError, error: errorMessage } = UseDownloadFileAuthorizatorCompletedSys(cbSuccessDownloadFileCompleted)


    const showToasDownloadtErrMessage = () => {
        if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
            const errorResponse = errorMessage as ErrorResponse;
            toast.error(`${translate("ERROR.DOWNLOAD.MESSAGE")}`, { toastId: 'Error6' });
        }
    }


    const {
        kamsSelect,
        isLoadingDataAuthorizator,
        clientsSelect,
        isLoadingDataClients,
        handleMultiSelect,
        multiSelectKam,
        multiSelectClient,
        cleanSelectKamAndClient,
        username,
        serviceClient,
        selectAllClient,
        selectAllKam,
        onSelectAll,
    } = useFiltersAuthorizationSys({ filterData, setFilterData, fnData: fnDataCompleted })



    useEffect(() => {
        fnDataCompleted({})
    }, [])



    useEffect(() => {
        if (dataAuthCompleted) {
            const parsePagination = JSON.parse(dataAuthCompleted?.headers['x-pagination'])
            const formattedPagination = {
                totalElements: parsePagination.totalCount,
                totalPages: parsePagination.totalPages,
                pageSize: parsePagination.pageSize,
                pageNumber: parsePagination.currentPage,
            }
            setPaging(formattedPagination)
        }
    }, [dataAuthCompleted])

    const onFilterClear = (): void => {
        cleanSelectKamAndClient()
        fnDataCompleted({})
        setFilterData({})
        setSelectedValues([])
    }

    const onFilter = (e: DataTablePageEvent): void => {
        const formattedFilters = formatTableFilters(e as any)

        setFilterData({
            ...filterData,
            ...formattedFilters,
            username,
            serviceClient,

        })
        fnDataCompleted({
            ...formattedFilters,
            username,
            serviceClient,

        })


    }

    const onPage = (e: DataTablePageEvent): void => {


        setFilterData({
            ...filterData,
            pageNumber: e.page,
            pageSize: e.rows,
            username,
            serviceClient,
        })
        fnDataCompleted({
            pageNumber: e.page,
            pageSize: e.rows,
            username,
            serviceClient,
        })
    }

    const onSort = (e: DataTablePageEvent): void => {
        const finalOrder = tableSortValueToSortDirection(e.sortOrder)


        setFilterData({
            ...filterData,
            sortField: e.sortField,
            sortOrder: finalOrder,
            username,
            serviceClient,
        })
        fnDataCompleted({
            sortField: e.sortField,
            sortOrder: finalOrder,
            username,
            serviceClient,
        })


    }

    const onSelectionChange = useCallback((values: any[]) => {
        setSelectedValues(values)
    }, [])








    const getHeadersButtons = () => (
        <>


            {permissions.canWrite && (
                <UfinetButton
                    isDisabled={isLoadingDownloadFile}
                    className="me-3"
                    icon={faDownload}
                    onClick={() => downloadFile(filterData)}
                    content={translate('BUTTON.DOWNLOAD')}
                />
            )}
        </>
    )

    const filterButtons = () => {
        return (
            <>
                <MultiSelectFiltersHeaders
                    // kamFilter={false}

                    onSelectAll={onSelectAll}
                    selectAllClient={selectAllClient}
                    selectAllKam={selectAllKam}
                    multiSelectClient={multiSelectClient}
                    multiSelectKam={multiSelectKam}
                    clientsSelect={clientsSelect}
                    kamsSelect={kamsSelect}
                    onChangeHandle={handleMultiSelect}
                    isLoadingDataAuthorizator={isLoadingDataAuthorizator}
                    isLoadingDataClients={isLoadingDataClients}
                />
            </>
        )

    }



    return (
        <>
            <UfinetBox>
                {isError && showToasDownloadtErrMessage()}

                <UfinetSectionBox>
                    <Table
                        filterButtons={filterButtons()}
                        dataKey="id"
                        selectedValues={selectedValues}
                        // onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
                        headerButtons={getHeadersButtons()}
                        enableSearchFilter={false}
                        // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
                        cols={colsRejectedPage}
                        content={dataAuthCompleted ? dataAuthCompleted?.data?.commissions?.map((item: CommissionElement) => ({
                            ...item,
                            username: item.user.username,
                            idService: item.service.idService,
                            saleMonth: item.service.saleMonth,
                            commissionType: item.commission.commissionType,
                            exchangeRate: item.commission.exchangeRate,
                            authorizationState: item.authorization.authorizationState,
                            id: item.commission.id,
                            totalAmount: item.commission.totalAmount,
                            backlog: item.service.backlog,
                            percentageCommission: item.commission.percentageCommission,
                            baseAmount: item.commission.baseAmount,
                            achievementFactor: item.commission.achievementFactor,
                            paymentYear: item.commission.paymentYear,
                            commissionTypePercentage: item.commission.commissionTypePercentage,
                            rejectedDate: item.authorization.rejectedDate,
                            saleYear: item.service.saleYear,
                            idServiceCRM: item.service.idServiceCRM,
                            serviceName: item.service.serviceName,
                            serviceClient: item.service.serviceClient,
                            authorizationReason: item.authorization.authorizationReason,
                            totalAmountUSD: item.commission.totalAmountUSD,
                            baseAmountUSD: item.commission.baseAmountUSD,
                            additionalInfo: item.commission.additionalInfo,
                        })) : []}
                        actionBodyTemplate={<></>}
                        rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
                        emptyMessage={isLoadingAuthDataCompleted || isLoadingDownloadFile ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
                        onFilterClear={onFilterClear}
                        lazySettings={
                            paging && {
                                ...paging,
                                loading: isLoadingAuthDataCompleted || isLoadingDownloadFile,
                                onFilter,
                                onPage,
                                onSort,
                            }
                        }
                    />




                </UfinetSectionBox>
            </UfinetBox>
        </>
    )
}

export { CompletedAuthorizationSysPage }

