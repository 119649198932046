import { AsideDefault } from 'layout/aside/AsideDefault'
import { AsideMobile } from 'layout/aside/AsideMobile'
import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import { Authority, UserTypesEnum, useTranslator } from 'ufinet-web-functions'



//* Authorization
import { PendingAuthorizationPage } from 'pages/authorization/pending/AuthotizationPendingPage'
import { RejectedAuthorizationPage } from 'pages/authorization/completed/RejectedAuthorizationPage'


//* Authorization system
import { PendingAuthorizationSysPage } from 'pages/authorization-sys/pending/PendingAuthorizationSysPage'
import { CompletedAuthorizationSysPage } from 'pages/authorization-sys/completed/CompletedPage'

export const PATH_HOME = ''
const PENDING_NAME = 'pending'
const COMPLETED_NAME = 'completed'

export const PATH_COMMISSIONS = 'commissions'
export const PATH_PENDING_AUTHORIZATION_PAGE = 'pending-authorization'
export const PATH_REJECT_AUTHORIZATION_PAGE = 'completed-authorization'


export const PATH_PENDING_AUTHORIZATION_SYS_PAGE = 'pending-authorization-sys'
export const PATH_COMPLETED_AUTHORIZATION_SYS_PAGE = 'completed-authorization-sys'

export const PATH_MODEL_PENDING_AUTHORIZATION_PAGE = `${PATH_PENDING_AUTHORIZATION_PAGE}/${PENDING_NAME}`
export const PATH_MODEL_REJECT_AUTHORIZATION_PAGE = `${PATH_REJECT_AUTHORIZATION_PAGE}/${COMPLETED_NAME}`


export const PATH_MODEL_COMPLETED_AUTHORIZATION_SYS_PAGE = `${PATH_COMPLETED_AUTHORIZATION_SYS_PAGE}/${COMPLETED_NAME}`
export const PATH_MODEL_PENDING_AUTHORIZATION_SYS_PAGE = `${PATH_PENDING_AUTHORIZATION_SYS_PAGE}/${PENDING_NAME}`

export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PENDING_NAME, 'PENDING.TITLE'],
	[COMPLETED_NAME, 'TITLE.COMPLETED'],

	//* COMMISSIONS
	[PATH_COMMISSIONS, 'MENU.TITLE.COMMISSIONS'],

	//* Authorization
	[PATH_PENDING_AUTHORIZATION_PAGE, 'TITLE.COMMISSION'],
	[PATH_REJECT_AUTHORIZATION_PAGE, 'TITLE.COMMISSION'],

	//* Authorization system
	[PATH_PENDING_AUTHORIZATION_SYS_PAGE, 'TITLE.COMMISSION'],
	[PATH_COMPLETED_AUTHORIZATION_SYS_PAGE, 'TITLE.COMMISSION'],
])

export const routerLinkMap = new Map<string, string>([
	[PATH_HOME, `/${PATH_HOME}`],
	// [PATH_INVOICES, `/${PATH_INVOICES}`],
	// [PATH_COMMISSIONS, `/${PATH_COMMISSIONS}`],
])

export const routePermissionMap = new Map<string, Authority[]>([

	//* COMMISSIONS
	[PATH_COMMISSIONS, [Authority.commissionAuthorizationModuleRead]],

	//* Authorization
	[PATH_PENDING_AUTHORIZATION_PAGE, [Authority.commissionAuthorizationModuleRead]],
	[PATH_REJECT_AUTHORIZATION_PAGE, [Authority.commissionAuthorizationModuleRead]],

	//* Authorization system
	[PATH_PENDING_AUTHORIZATION_SYS_PAGE, [Authority.commissionAuthorizationSysModuleRead]],
	[PATH_COMPLETED_AUTHORIZATION_SYS_PAGE, [Authority.commissionAuthorizationSysModuleRead]],
])

const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route path={PATH_HOME} element={<WelcomePage appName={translate('HOME.TITLE')} />} />

				{/* //!Authorization */}

				<Route
					path={PATH_MODEL_PENDING_AUTHORIZATION_PAGE}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_PENDING_AUTHORIZATION_PAGE)!}
						>
							<PendingAuthorizationPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_MODEL_REJECT_AUTHORIZATION_PAGE}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_REJECT_AUTHORIZATION_PAGE)!}
						>
							<RejectedAuthorizationPage />
						</AuthRoute>
					}
				/>

				{/* //! Authorization System */}

				<Route
					path={PATH_MODEL_PENDING_AUTHORIZATION_SYS_PAGE}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_PENDING_AUTHORIZATION_SYS_PAGE)!}
						>
							<PendingAuthorizationSysPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_MODEL_COMPLETED_AUTHORIZATION_SYS_PAGE}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_COMPLETED_AUTHORIZATION_SYS_PAGE)!}
						>
							<CompletedAuthorizationSysPage />
						</AuthRoute>
					}
				/>

				<Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
