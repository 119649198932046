import { useQuery } from "react-query"
import { fetchGET, fetchGETCounter, fetchGETCounterSys, fetchGETSYS } from "utils/https/https"

export const UseQueryGetTotalPendingAuthorizationCommissions = () => {



    return useQuery(['UseQueryGetTotalPendingAuthorizationCommissions'], async () => {
        try {
            const response = await fetchGET(`commissions/Authorizator/ListCommissions?&authorizationState=pending&commissionState=inProcess`)
            if (response.headers['x-pagination']) {
                const parsedHeaders = JSON.parse(response.headers['x-pagination'])
                return parsedHeaders.totalCount
            }
            return response
        } catch (error) {
            throw error
        }
    })
}

export const UseQueryGetTotalRejectedAuthorizationCommissions = () => {



    return useQuery(['UseQueryGetTotalRejectedAuthorizationCommissions'], async () => {
        try {
            const response = await fetchGET(`commissions/Authorizator/ListCommissions?&authorizationState=rejected`)
            if (response.headers['x-pagination']) {
                const parsedHeaders = JSON.parse(response.headers['x-pagination'])
                return parsedHeaders.totalCount
            }
            return response
        } catch (error) {
            throw error
        }
    })
}


export interface TotalData {
    user: string;
    pending: number;
    commissions: number;
}



export const UseQueryGetTotalCommissions = () => {

    return useQuery(['UseQueryGetTotalCommissions'], async (): Promise<TotalData> => {
        try {
            const response = await fetchGETCounter(`approvals/ContextMenu/GetPendingApprovalsCount`)

            return response.data
        } catch (error) {
            throw error
        }
    })
}

export const UseQueryGetTotalCommissionsSys = () => {

    return useQuery(['UseQueryGetTotalCommissionsSys'], async (): Promise<TotalData> => {
        try {
            const response = await fetchGETCounterSys(`approvals/ContextMenu/GetPendingApprovalsCount`)

            return response.data
        } catch (error) {
            throw error
        }
    })
}