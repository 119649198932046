import { createContext } from "react"



export interface CounterContextProps {
    refetchData: () => void
    refetchDataSys: () => void
    pending: number
    commission: number
    pendingSys: number
    commissionSys: number
}


export const CounterContext = createContext<CounterContextProps>({} as CounterContextProps)


