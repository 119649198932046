import { ReactNode, useState } from "react"
import { CounterContext } from './CounterContext'
import { UseQueryGetTotalPendingAuthorizationCommissions, UseQueryGetTotalRejectedAuthorizationCommissions, UseQueryGetTotalCommissions, UseQueryGetTotalCommissionsSys } from "request"


interface Props {
    children: JSX.Element | JSX.Element[]
}

export const CounterProvider = ({ children }: Props) => {
    const { data: dataTotal, refetch: refetchData } = UseQueryGetTotalCommissions()
    const { data: dataTotalSys, refetch: refetchDataSys } = UseQueryGetTotalCommissionsSys()

    return (
        <CounterContext.Provider
            value={{
                pending: dataTotal?.pending || 0,
                commission: dataTotal?.commissions || 0,
                refetchData,
                refetchDataSys,
                pendingSys: dataTotalSys?.pending || 0,
                commissionSys: dataTotalSys?.commissions || 0,
            }}
        >
            {children}

        </CounterContext.Provider>
    )
}