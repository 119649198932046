import axios from "axios"
import { useMutation, useQuery } from "react-query"
import { fetchGET, fetchPOST, fetchPOST2Blob } from "utils/https/https"

export const UseQueryPendingAuthorizationCommissions = (data: any) => {

    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType : null
    const type = data?.type && data?.type.length > 0 ? data?.type : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth : null
    const username = data?.username ? data?.username : null
    const serviceClient = data?.serviceClient && data?.serviceClient.length > 0 ? data?.serviceClient : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    return useQuery(['UseQueryPendingAuthorizationCommissions', username, serviceId, type, commissionType, serviceName, serviceClient, idServiceCRM, saleMonth, paymentMonth, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
        try {
            const serchParms = new URLSearchParams();

            if (username) {
                serchParms.append('username', username)
            }
            if (commissionType) {
                serchParms.append('commissionType', commissionType.join(','))
            }

            if (serviceClient) {
                serchParms.append('serviceClient', serviceClient.join(','))
            }

            if (serviceName) {
                serchParms.append('serviceName', serviceName)
            }

            if (idServiceCRM) {
                serchParms.append('idServiceCRM', idServiceCRM)
            }

            if (serviceId) {
                serchParms.append('IdService', serviceId)
            }

            if (type) {
                serchParms.append('type', type.join(','))
            }

            if (saleMonth) {
                serchParms.append('saleMonth', saleMonth.join(','))
            }

            if (paymentMonth) {
                serchParms.append('paymentMonth', paymentMonth)
            }

            if (saleYear) {
                serchParms.append('saleYear', saleYear)
            }


            // PAGINATION
            if (pageNumber) {
                serchParms.append('pageNumber', pageNumber)
            }

            if (pageSize) {
                serchParms.append('rowsPerPage', pageSize)
            }

            if (sortOrder) {
                serchParms.append('sortOrder', sortOrder)
            }

            if (sortField) {
                serchParms.append('sortField', sortField)
            }

            const response = await fetchGET(`commissions/Authorizator/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`)
            return response
        } catch (error) {
            throw error
        }
    })
}



export const UseQueryRejectedAuthorizationCommissions = (data: any) => {

    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const username = data?.username ? data?.username : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const authorizationReason = data?.authorizationReason ? data?.authorizationReason : null
    const serviceClient = data?.serviceClient && data?.serviceClient !== '' ? data?.serviceClient : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    return useQuery(['UseQueryRejectedAuthorizationCommissions', paymentMonth, type, authorizationReason, saleMonth, username, idServiceCRM, serviceClient, serviceName, serviceId, saleYear, pageNumber, pageSize, sortOrder, sortField], async () => {
        try {
            const serchParms = new URLSearchParams();

            if (type) {
                serchParms.append('type', type)
            }

            if (serviceClient) {
                serchParms.append('serviceClient', serviceClient)
            }

            if (authorizationReason) {
                serchParms.append('authorizationReason', authorizationReason)
            }

            if (serviceName) {
                serchParms.append('serviceName', serviceName)
            }

            if (idServiceCRM) {
                serchParms.append('idServiceCRM', idServiceCRM)
            }

            if (saleMonth) {
                serchParms.append('saleMonth', saleMonth)
            }

            if (username) {
                serchParms.append('username', username)
            }

            if (serviceId) {
                serchParms.append('IdService', serviceId)
            }

            if (saleYear) {
                serchParms.append('saleYear', saleYear)
            }


            // PAGINATION
            if (pageNumber) {
                serchParms.append('pageNumber', pageNumber)
            }

            if (pageSize) {
                serchParms.append('rowsPerPage', pageSize)
            }

            if (sortOrder) {
                serchParms.append('sortOrder', sortOrder)
            }

            if (sortField) {
                serchParms.append('sortField', sortField)
            }

            const response = await fetchGET(`commissions/Authorizator/ListCommissions?&authorizationState=rejected&${serchParms.toString()}`)
            return response
        } catch (error) {
            throw error
        }
    })
}

const UseApproveCommissionAuthorization = async (data: any) => {

    try {
        const response = await fetchPOST(`commissions/Authorizator/AuthorizeCommissions`, data)
        return response
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const customErr = {
                errorCode: error.response.data.code,
                message: error.response.data.message,
                reason: error.response.data.reason,
            }

            throw customErr
        }
        throw error
    }

}

export const UsePostApproveCommissionAuthorization = (callbackSuccess: any) => {
    return useMutation(UseApproveCommissionAuthorization, {
        onSuccess: (data) => {
            callbackSuccess(data);
        },
    });
};


const UseRejectCommissionAuthorization = async (data: any) => {

    try {
        const response = await fetchPOST(`commissions/Authorizator/AuthorizeCommissions`, data)
        return response
    } catch (error: unknown) {
        if (axios.isAxiosError(error) && error.response) {
            const customErr = {
                errorCode: error.response.data.code,
                message: error.response.data.message,
                reason: error.response.data.reason,
            }

            throw customErr
        }
        throw error
    }

}

export const UsePostRejectCommissionAuthorization = (callbackSuccess: any) => {
    return useMutation(UseRejectCommissionAuthorization, {
        onSuccess: (data) => {
            callbackSuccess(data);
        },
    });
};


export const UseGetKams = (data: any) => {
    const username = data && data.username ? data.username : null


    return useQuery(['UseGetKams', username], async () => {
        try {
            const serchParms = new URLSearchParams();

            if (username) {
                serchParms.append('username', username)
            }

            const response = await fetchGET(`commissions/User/ListUsers?&${serchParms.toString()}`)
            return response

        } catch (error) {
            throw error
        }

    })
}
export const UseGetClients = (data: any) => {

    const name = data && data.name ? data.name : null


    return useQuery(['UseGetClients', name], async () => {
        try {
            const serchParms = new URLSearchParams();

            if (name) {
                serchParms.append('name', name)
            }

            const response = await fetchGET(`commissions/Client/ListClients?&${serchParms.toString()}`)
            return response

        } catch (error) {
            throw error
        }

    })
}



type RequestBody = {
    username?: string
    serviceClient?: string
}


const postDataAuthorizationPending = async (data: any) => {


    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType : null
    const type = data?.type && data?.type.length > 0 ? data?.type : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null



    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null





    try {



        const serchParms = new URLSearchParams();


        if (commissionType) {
            serchParms.append('commissionType', commissionType.join(','))
        }



        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (type) {
            serchParms.append('type', type.join(','))
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth.join(','))
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        // PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }


        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }

        const response = await fetchPOST(`commissions/Authorizator/ListCommissions?&authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
        return response



    } catch (error) {
        throw error
    }
}



export const UseMutationDataAuthPending = (cb: any) => {
    return useMutation(postDataAuthorizationPending, {
        onSuccess: (data) => {
            cb(data)
        }
    })

}


const postDataAuthorizationRejected = async (data: any) => {


    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth : null
    const commissionType = data?.commissionType && data?.commissionType.length > 0 ? data?.commissionType : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const authorizationReason = data?.authorizationReason ? data?.authorizationReason : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
    const authorizationState = data.authorizationState && data.authorizationState.length > 0 ? data.authorizationState : null

    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


    try {



        const serchParms = new URLSearchParams();

        if (type) {
            serchParms.append('type', type.join(','))
        }

        if (authorizationState) {
            serchParms.append('authorizationState', authorizationState.join(','))
        }

        if (commissionType) {
            serchParms.append('commissionType', commissionType.join(','))
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (authorizationReason) {
            serchParms.append('authorizationReason', authorizationReason)
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth.join(','))
        }



        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        // PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }



        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }

        const response = await fetchPOST(`commissions/Authorizator/ListCommissions?&CommissionState=finished&${serchParms.toString()}`, requestBody)
        return response



    } catch (error) {
        throw error
    }
}



export const UseMutationDataAuthRejected = (cb: any) => {
    return useMutation(postDataAuthorizationRejected, {
        onSuccess: (data) => {
            cb(data)
        }
    })

}




const downloadFileAuthorizatorPending = async (data: any) => {

    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null

    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    try {

        const serchParms = new URLSearchParams();

        if (username) {
            serchParms.append('username', username)
        }

        if (serviceClient) {
            serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (type) {
            serchParms.append('type', type)
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth)
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        //! PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }

        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }


        const response = await fetchPOST2Blob(`commissions/Authorizator/DownloadCommissions?authorizationState=pending&commissionState=inProcess&${serchParms.toString()}`, requestBody)
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'authorizatorPendingDocument.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);


        return response


    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const customErr = {
                errorCode: error.response.data.code,
                message: error.response.data.message,
                reason: error.response.data.reason,
            }

            throw customErr
        }
    }
}


export const UseDownloadFileAuthorizatorPending = (callbackSuccess: any) => {
    return useMutation(downloadFileAuthorizatorPending, {
        onSuccess(data) {
            callbackSuccess(data)
        },
    })
}



const downloadFileAuthorizatorCompleted = async (data: any) => {

    const paymentMonth = data?.paymentMonth && data?.paymentMonth.length > 0 ? data?.paymentMonth[0] : null
    const type = data?.type && data?.type.length > 0 ? data?.type[0] : null
    const saleMonth = data?.saleMonth && data?.saleMonth.length > 0 ? data?.saleMonth[0] : null
    const serviceId = data?.idService ? data?.idService : null
    const idServiceCRM = data?.idServiceCRM && data?.idServiceCRM.length > 0 ? data?.idServiceCRM[0] : null
    const serviceName = data?.serviceName && data?.serviceName !== '' ? data?.serviceName : null
    const saleYear = data?.saleYear && data?.saleYear.length > 0 ? data?.saleYear[0] : null
    const authorizationState = data.authorizationState && data.authorizationState.length > 0 ? data.authorizationState[0] : null

    const username = Array.isArray(data?.username) ? data.username : [];
    const serviceClient = Array.isArray(data?.serviceClient) ? data.serviceClient : [];


    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    try {

        const serchParms = new URLSearchParams();

        if (username) {
            serchParms.append('username', username)
        }

        if (authorizationState) {
            serchParms.append('authorizationState', authorizationState)
        }

        if (serviceClient) {
            serchParms.append('serviceClient', serviceClient)
        }

        if (serviceName) {
            serchParms.append('serviceName', serviceName)
        }

        if (idServiceCRM) {
            serchParms.append('idServiceCRM', idServiceCRM)
        }

        if (serviceId) {
            serchParms.append('IdService', serviceId)
        }

        if (type) {
            serchParms.append('type', type)
        }

        if (saleMonth) {
            serchParms.append('saleMonth', saleMonth)
        }

        if (paymentMonth) {
            serchParms.append('paymentMonth', paymentMonth)
        }

        if (saleYear) {
            serchParms.append('saleYear', saleYear)
        }


        //! PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }

        const requestBody: RequestBody = {};

        if (username.length > 0) {
            requestBody.username = username.join(',');
        }
        if (serviceClient.length > 0) {
            requestBody.serviceClient = serviceClient.join(',');
        }


        const response = await fetchPOST2Blob(`commissions/Authorizator/DownloadCommissions?&CommissionState=finished&${serchParms.toString()}`, requestBody)
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'authorizatorCompletedDocument.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);


        return response


    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const customErr = {
                errorCode: error.response.data.code,
                message: error.response.data.message,
                reason: error.response.data.reason,
            }

            throw customErr
        }
    }
}


export const UseDownloadFileAuthorizatorCompleted = (callbackSuccess: any) => {
    return useMutation(downloadFileAuthorizatorCompleted, {
        onSuccess(data) {
            callbackSuccess(data)
        },
    })
}