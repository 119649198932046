import React, { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react'
import { useIntl } from 'react-intl'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetAsyncButton, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, useTranslator, } from 'ufinet-web-functions'
import { colsPendingAuthorization } from './AuthorizatonPendingColData'
import { UseQueryPendingAuthorizationCommissions, UsePostApproveCommissionAuthorization, UsePostRejectCommissionAuthorization, UseMutationDataAuthPending, UseDownloadFileAuthorizatorPending, } from 'request'

import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement } from 'types/commisions/CommisionElementTypes'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { RejectModalForm, ApprovedBodyForm } from './modals'
import { CounterContext } from 'context/CounterContext'
import { ErrorResponse } from 'types/typesAndValues'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { FiltersHeaders } from '../filterHeaders/FiltersHeaders'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'


const PendingAuthorizationPage = () => {
  const translate = useTranslator()
  const intl = useIntl()


  //* MODALS
  const [rejecteModal, showRejectModal, hideRejectModal] = useModal()
  const [approvedModal, showApprovedModal, hideApprovedModal] = useModal()



  const colsPendingPage = useMemo(() => colsPendingAuthorization(intl), [intl])

  const authData = useContext(AuthContext)
  const { refetchData } = useContext(CounterContext)

  const userEmail = authData.userData?.username
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionAuthorizationPermissions(roles)


  const [selectedValues, setSelectedValues] = useState<CommissionElement[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>({})
  const isSelected = selectedValues && selectedValues.length > 0



  const cbSuccessDataAuthPending = ({ data }: any) => {
  }

  const cbSuccessDownloadFilePending = ({ data }: any) => {
  }


  const { mutate: fnDataPending, data: dataPending, isLoading: isLoadingDataPending } = UseMutationDataAuthPending(cbSuccessDataAuthPending)
  const { mutate: downloadFile, isLoading: isLoadinDownloadingFile, isError: isErrorDownloadFile, error: errorMessageFile } = UseDownloadFileAuthorizatorPending(cbSuccessDownloadFilePending)


  const showToasDownloadtErrMessage = () => {
    if (typeof errorMessageFile === 'object' && errorMessageFile !== null && 'errorCode' in errorMessageFile) {
      const errorResponse = errorMessageFile as ErrorResponse;
      toast.error(`${translate("ERROR.DOWNLOAD.MESSAGE")}`, { toastId: 'Error6' });
    }
  }





  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorization({ filterData, setFilterData, fnData: fnDataPending })





  const isSelectValues = selectedValues && selectedValues.length > 0



  const cbSuccessApproveCommission = ({ data }: any) => {
    setSelectedValues([])
    hideApprovedModal()
    toast.success(data.message)
    refetchData()
    fnDataPending({})
    setFilterData({})
  }

  const { mutate: approveCommission, isLoading: isLoadingApproveCommission, isError: isErrorApprove, error: errorMessage } = UsePostApproveCommissionAuthorization(cbSuccessApproveCommission)

  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      setSelectedValues([])
      refetchData()
      setFilterData({})
    }
  }


  useEffect(() => {
    fnDataPending({})
  }, [])


  useEffect(() => {
    if (dataPending) {
      const parsePagination = JSON.parse(dataPending?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [dataPending])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    setFilterData({})
    setSelectedValues([])
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })

    fnDataPending({
      ...filterData,
      ...formattedFilters,
      username,
      serviceClient,
    })

  }

  const onPage = (e: DataTablePageEvent): void => {

    setFilterData({
      ...filterData,
      pageNumber: e.page,
      pageSize: e.rows,
      username,
      serviceClient,
    })

    fnDataPending({
      ...filterData,
      pageNumber: e.page,
      pageSize: e.rows,
      username,
      serviceClient,
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)

    setFilterData({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
      username,
      serviceClient,
    })

    fnDataPending({
      ...filterData,
      sortField: e.sortField,
      sortOrder: finalOrder,
      username,
      serviceClient,
    })
  }

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])


  const approveCommisionFn = () => {
    if (isSelectValues) {
      const newArray = selectedValues.map((item: any) => ({
        id: item.id,
        state: 'approved'
      }))
      approveCommission(newArray)
    }
  }


  const dowloadFileFn = () => {
    downloadFile(filterData)
  }





  const getHeadersButtons = () => (
    <>

      {permissions.canWrite && isSelected && (
        <UfinetButton
          isDisabled={isLoadingApproveCommission}
          className="me-1"
          icon={faCircleCheck}
          onClick={showApprovedModal}
          content={translate('BUTTON.APRROVED')}
        />
      )}

      {permissions.canWrite && isSelected && (
        <UfinetButton
          className="me-1"
          icon={faCircleXmark}
          onClick={showRejectModal}
          content={translate('BUTTON.REJECTED')}
        />
      )}
      {permissions.canWrite && (
        <UfinetButton
          isDisabled={isLoadinDownloadingFile}
          icon={faDownload}
          onClick={dowloadFileFn}
          content={translate('BUTTON.DOWNLOAD')}
        />
      )}
    </>
  )


  const filterButtons = () => {
    return (
      <>

        <MultiSelectFiltersHeaders
          // kamFilter={false}
          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }






  return (
    <>
      {isErrorApprove && showToastErrMessage()}
      {isErrorDownloadFile && showToasDownloadtErrMessage()}
      <UfinetBox>

        <UfinetSectionBox>
          <Table
            filterButtons={filterButtons()}
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
            headerButtons={getHeadersButtons()}
            enableSearchFilter={false}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsPendingPage}
            content={dataPending ? dataPending?.data?.commissions?.map((item: CommissionElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              saleYear: item.service.saleYear,
              authorizationState: item.authorization.authorizationState,
              id: item.commission.id,
              achievementFactor: item.commission.achievementFactor,
              baseAmount: item.commission.baseAmount,
              percentageCommission: item.commission.percentageCommission,
              backlog: item.service.backlog,
              totalAmount: item.commission.totalAmount,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              baseAmountUSD: item.commission.baseAmountUSD,
              totalAmountUSD: item.commission.totalAmountUSD,
              additionalInfo: item.commission.additionalInfo,
            })) : []}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingDataPending || isLoadingApproveCommission ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingDataPending || isLoadingApproveCommission,
                onFilter,
                onPage,
                onSort,
              }
            }
            // customClassFilters=""
            // customClassFilters="col-xl-6 col-md-12 col-sm-12"
            customClassHeaders="p-0 m-0"
          />
        </UfinetSectionBox>
        <UfinetModal
          size="lg"
          show={rejecteModal}
          handleClose={hideRejectModal}
          title={translate('BUTTON.REJECTED')}
        >
          <RejectModalForm
            refetchDataTable={fnDataPending}
            hideRejectModal={hideRejectModal}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setFilterData={setFilterData}
          />
        </UfinetModal>
        <UfinetModal
          size="lg"
          show={approvedModal}
          handleClose={hideApprovedModal}
          title={translate('APPROVED.MESSAGE')}
        >
          <ApprovedBodyForm
            approveCommisionFn={approveCommisionFn}
            hideApprovedModal={hideApprovedModal}
            isLoadingApproveCommission={isLoadingApproveCommission}
          />
        </UfinetModal>
      </UfinetBox>
    </>
  )
}

export { PendingAuthorizationPage }

