/* eslint-disable react/jsx-no-target-blank */
import { FC, useCallback, useContext, useEffect, useState } from 'react'

import {
	PATH_COMMISSIONS,
	PATH_PENDING_AUTHORIZATION_PAGE,
	PATH_MODEL_PENDING_AUTHORIZATION_PAGE,
	PATH_REJECT_AUTHORIZATION_PAGE,
	PATH_MODEL_REJECT_AUTHORIZATION_PAGE,
	PATH_PENDING_AUTHORIZATION_SYS_PAGE,
	PATH_COMPLETED_AUTHORIZATION_SYS_PAGE,
	PATH_MODEL_COMPLETED_AUTHORIZATION_SYS_PAGE,
	PATH_MODEL_PENDING_AUTHORIZATION_SYS_PAGE,
	routerNameMap
} from '../../routing/protected/PrivateRoutes'

import { faCircleDot, faFileInvoice, faStamp, faCircle, faTriangleExclamation, faCheck, faSquareCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser, useTranslator } from 'ufinet-web-functions'
import { CounterContext } from 'context/CounterContext'
import { UseQueryGetTotalPendingAuthorizationCommissions, UseQueryGetTotalRejectedAuthorizationCommissions } from 'request'
import { icon } from '@fortawesome/fontawesome-svg-core'

enum AsideMenu {
	NONE,
	CONFIGURATION,
	COMMISSION_PENDING,
	COMMISSION_SYS_PENDING,
	COMMISSION_COMPLETED,
	COMMISSION_SYS_COMPLETED,
}

enum PrintValues {
	PENDING = 'pending',
	// PENDING_COMMSSIONS = 'hola'
}


interface IMenuItemPrint extends IMenuItem {
	id: PrintValues
}


type TemplateRoutes = {
	[key in PrintValues]: string;
};

const AsideMenuMain: FC = () => {






	const {
		pending,
		commission,
		pendingSys,
		commissionSys,
	} = useContext(CounterContext)




	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isConfigurationActive = () => active === AsideMenu.CONFIGURATION
	const isCommissionPendingActive = () => active === AsideMenu.COMMISSION_PENDING
	const isCommissionCompletedActive = () => active === AsideMenu.COMMISSION_COMPLETED
	const isCommissionSysPendingActive = () => active === AsideMenu.COMMISSION_SYS_PENDING
	const isCommissionSysCompletedActive = () => active === AsideMenu.COMMISSION_SYS_COMPLETED



	const roles = userData?.authorities || []

	const invoicePermissions = Authority.getGfaInvoicePermissions(roles)
	const commissionPermissions = Authority.getCommissionAuthorizationPermissions(roles)
	const commissionSystemPermissions = Authority.getCommissionAuthorizationSysPermissions(roles)
	const isInternal = useInternalUser()


	const menuCommissionsItems: IMenuItemPrint[] = [
		{
			to: PATH_MODEL_PENDING_AUTHORIZATION_PAGE,
			title: routerNameMap.get(PATH_PENDING_AUTHORIZATION_PAGE),
			id: PrintValues.PENDING,
		},
	].map((it) => ({ ...it, hidden: !commissionPermissions.canRead }))

	const menuCommissionCompleted: IMenuItem[] = [

		{
			to: PATH_MODEL_REJECT_AUTHORIZATION_PAGE,
			title: routerNameMap.get(PATH_REJECT_AUTHORIZATION_PAGE),
		},
	].map((it) => ({ ...it, hidden: !commissionPermissions.canRead }))


	const menuPendingComissionSystemItems: IMenuItemPrint[] = [
		{
			to: PATH_MODEL_PENDING_AUTHORIZATION_SYS_PAGE,
			title: routerNameMap.get(PATH_PENDING_AUTHORIZATION_SYS_PAGE,),
			id: PrintValues.PENDING,
		},
	].map((it) => ({ ...it, hidden: !commissionSystemPermissions.canRead }))

	const menuCompletedComissionSystemItems: IMenuItem[] = [
		{
			to: PATH_MODEL_COMPLETED_AUTHORIZATION_SYS_PAGE,
			title: routerNameMap.get(PATH_COMPLETED_AUTHORIZATION_SYS_PAGE),

		},
	].map((it) => ({ ...it, hidden: !commissionSystemPermissions.canRead }))



	const templateString = useCallback((title: string, id: PrintValues) => {

		const templateRoutes: TemplateRoutes = {
			[PrintValues.PENDING]: `${translate(title)} (${commission})`,
		}

		return templateRoutes[id]

	}, [pending, commission])

	const templateStringSys = useCallback((title: string, id: PrintValues) => {

		const templateRoutes: TemplateRoutes = {
			[PrintValues.PENDING]: `${translate(title)} (${commissionSys})`,
		}

		return templateRoutes[id]

	}, [pendingSys, commissionSys])



	return (
		<>



			{commissionPermissions.canRead && (
				<AsideMenuItemWithSub
					title={pending ? `${translate('TITLE.PENDING')} (${pending})` : `${translate('TITLE.PENDING')} (0)`}
					active={isCommissionPendingActive()}
					icon={<FontAwesomeIcon icon={faTriangleExclamation} className="fs-2x" />}
				>
					{menuCommissionsItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? templateString(item.title, item.id) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_PENDING)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{commissionPermissions.canRead && (
				<AsideMenuItemWithSub
					title={translate('TITLE.COMPLETED2')}
					active={isCommissionCompletedActive()}
					icon={<FontAwesomeIcon icon={faStamp} className="fs-2x" />}
				>
					{menuCommissionCompleted
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_COMPLETED)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{commissionSystemPermissions.canRead && (
				<AsideMenuItemWithSub
					title={pendingSys ? `${translate('TITLE.PENDING')} (${pendingSys})` : `${translate('TITLE.PENDING')} (0)`}
					active={isCommissionSysPendingActive()}
					icon={<FontAwesomeIcon icon={faTriangleExclamation} className="fs-2x" />}
				>
					{menuPendingComissionSystemItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? templateStringSys(item.title, item.id) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_SYS_PENDING)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{commissionSystemPermissions.canRead && (
				<AsideMenuItemWithSub
					title={translate('TITLE.COMPLETED2')}
					active={isCommissionSysCompletedActive()}
					icon={<FontAwesomeIcon icon={faStamp} className="fs-2x" />}
				>
					{menuCompletedComissionSystemItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_SYS_COMPLETED)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

		</>
	)
}

export { AsideMenuMain }
