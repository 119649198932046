import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IUfinetSelectOption, UfinetActionButton, UfinetActionButtonHandle, UfinetInput, UfinetSelect, emptyUfinetSelectOption } from 'ufinet-web-components'
import { Dispatch, SetStateAction, memo, useCallback, useContext, useMemo, useRef } from 'react'
import { onFormikChanges, onFormikNumberChanges, onFormikTextChanges } from 'ufinet-web-functions'
import { useTranslation } from 'utils/translation/Translation'
import { useIntl } from 'react-intl'
import { ErrorResponse, IsActiveValues, isActiveMappings } from 'types/typesAndValues'
import { UsePostRejectCommissionAuthorization } from 'request'
import { toast } from 'react-toastify'
import { CommissionElement } from 'types/commisions/CommisionElementTypes'
import { CounterContext } from 'context/CounterContext'
import { UseMutateFunction } from 'react-query'





interface Props {
  hideRejectModal: () => void
  selectedValues: CommissionElement[]
  setSelectedValues: Dispatch<SetStateAction<CommissionElement[]>>
  setFilterData: Dispatch<SetStateAction<any>>
  refetchDataTable: UseMutateFunction
}

type RejectFormData = {
  reason: string
}

export const RejectModalForm = ({ refetchDataTable, hideRejectModal, selectedValues, setSelectedValues, setFilterData }: Props) => {

  const { refetchData } = useContext(CounterContext)


  const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
  const translate = useTranslation()
  const intl = useIntl()


  const cbSuccessRejectCommission = ({ data }: any) => {
    toast.success(data.message)
    setSelectedValues([])
    refetchData()
    setFilterData({})
    refetchDataTable()
    hideRejectModal()
  }

  const { mutate: rejectCommission, isLoading: isLoadingRejectCommission, isError, error: errorMessage } = UsePostRejectCommissionAuthorization(cbSuccessRejectCommission)


  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      hideRejectModal()
    }
  }


  const dataFormSchema = Yup.object().shape({
    reason: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
  })



  const sendData = (values: RejectFormData) => {
    actionButtonRef.current?.changeActionStatus(true)

    const newArray = selectedValues.map((item: any) => ({
      id: item.id,
      state: 'rejected',
      reason: values.reason,
    }))
    rejectCommission(newArray)
  }

  const onChange = useCallback(onFormikChanges, [])
  const onNumberChange = useCallback(onFormikNumberChanges, [])
  const onTextChange = useCallback(onFormikTextChanges, [])


  const initialValues: RejectFormData = useMemo<RejectFormData>(
    () => ({
      reason: ''
    }),
    []
  )



  const formik = useFormik<RejectFormData>({
    initialValues,
    validationSchema: dataFormSchema,
    onSubmit: sendData,
    validateOnChange: false,
    validateOnBlur: false,
  })





  return (
    <>
      {isError && showToastErrMessage()}
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='row'>

            <UfinetInput
              requiredIcon
              error={formik.errors.reason}
              type="text"
              labelTitle={translate('ADD.REASON')}
              className="col-6"
              onChange={onTextChange(formik, 'reason')}
              value={formik.values.reason}
              solid={false}
            />


            <UfinetActionButton
              isDisabled={isLoadingRejectCommission}
              ref={actionButtonRef}
              className='mt-5'
              content={translate('BUTTON.REJECTED')}
            />
          </div>
        </div>
      </form>
    </>
  )
}
