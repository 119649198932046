import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark'
import { toast } from 'react-toastify'
import { MultipleDeleter, Table, UfinetActionButton, UfinetBox, UfinetButton, UfinetModal, UfinetSectionBox, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components'
import { AuthContext, Authority, useAsync, useModal, user, } from 'ufinet-web-functions'
import { colsRejectedAuthorization } from './RejectedAuthorizionColData'
import { UseQueryRejectedAuthorizationCommissions, UsePostApproveCommissionAuthorization, UseMutationDataAuthRejected, UseDownloadFileAuthorizatorCompleted } from 'request'
import { Translation, useTranslation } from 'utils/translation/Translation'
import { DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable'
import { CommissionElement } from 'types/commisions/CommisionElementTypes'
import { faDownload, faUpload, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { CounterContext } from 'context/CounterContext'
import { AuthorizationStatus, ErrorResponse } from 'types/typesAndValues'
import { useFiltersAuthorization } from 'hooks/useFiltersAuthorization'
import { FiltersHeaders } from '../filterHeaders/FiltersHeaders'
import { MultiSelectFiltersHeaders } from '../filterHeaders/MultiSelectFiltersHeaders'
import { RevertBodyModal, WarningModal } from './modals'
import { icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RejectedAuthorizationPage = () => {
  const intl = useIntl()
  const translate = useTranslation()

  const colsRejectedPage = useMemo(() => colsRejectedAuthorization(intl), [intl])

  //* Modals
  const [revertModal, showRevertModal, hideRevertModal] = useModal()
  const [containApprovalModal, showApprovalModal, hideApprovalModal] = useModal()

  const authData = useContext(AuthContext)
  const { refetchData } = useContext(CounterContext)
  const userEmail = authData.userData?.username
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getCommissionAuthorizationPermissions(roles)


  const [selectedValues, setSelectedValues] = useState<CommissionElement[]>([])
  const [paging, setPaging] = useState<any>()
  const [filterData, setFilterData] = useState<any>({})

  const isSelected = selectedValues && selectedValues.length > 0
  const isAnyApproved = isSelected && selectedValues.some((item: CommissionElement) => item.authorization.authorizationState.toLowerCase() === AuthorizationStatus.APPROVED.toLowerCase())




  const cbSuccessDataAuthRejected = ({ data }: any) => {

  }

  const cbSuccessDownloadFile = ({ data }: any) => {

  }


  const { mutate: fnDataRejected, isLoading: isLoadingAuthDataRejected, data: dataAuthRejected } = UseMutationDataAuthRejected(cbSuccessDataAuthRejected)
  const { mutate: downloadFile, isLoading: isLoadingDownloadFile, isError: isErrorDownloadFile, error: errorMessageFile } = UseDownloadFileAuthorizatorCompleted(cbSuccessDownloadFile)


  const showToastDownloadErrMessage = () => {
    if (typeof errorMessageFile === 'object' && errorMessageFile !== null && 'errorCode' in errorMessageFile) {
      const errorResponse = errorMessageFile as ErrorResponse;
      toast.error(`${translate("ERROR.DOWNLOAD.MESSAGE")}`, { toastId: 'Error6' });

    }
  }


  const {
    kamsSelect,
    isLoadingDataAuthorizator,
    clientsSelect,
    isLoadingDataClients,
    handleMultiSelect,
    multiSelectKam,
    multiSelectClient,
    cleanSelectKamAndClient,
    username,
    serviceClient,
    selectAllClient,
    selectAllKam,
    onSelectAll,
  } = useFiltersAuthorization({ filterData, setFilterData, fnData: fnDataRejected })




  const cbSuccessRevertCommission = ({ data }: any) => {
    setSelectedValues([])
    setFilterData({})
    toast.success(data.message)
    fnDataRejected({})
    hideRevertModal()
    refetchData()
  }



  const { mutate: revertCommission, isLoading: isLoadingRevertCommission, isError, error: errorMessage } = UsePostApproveCommissionAuthorization(cbSuccessRevertCommission)



  const showToastErrMessage = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse;
      toast.error(`${translate("ERROR.MESSAGE")} ${errorResponse.errorCode}`, { toastId: 'Error5' });
      setSelectedValues([])
      fnDataRejected({})
      setFilterData({})
    }
  }

  useEffect(() => {
    fnDataRejected({})
  }, [])



  useEffect(() => {
    if (dataAuthRejected) {
      const parsePagination = JSON.parse(dataAuthRejected?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [dataAuthRejected])

  const onFilterClear = (): void => {
    cleanSelectKamAndClient()
    fnDataRejected({})
    setFilterData({})
    setSelectedValues([])
  }

  const onFilter = (e: DataTablePageEvent): void => {
    const formattedFilters = formatTableFilters(e as any)

    setFilterData({
      ...formattedFilters,
      username,
      serviceClient,

    })
    fnDataRejected({
      ...formattedFilters,
      username,
      serviceClient,

    })


  }

  const onPage = (e: DataTablePageEvent): void => {


    setFilterData({
      pageNumber: e.page,
      pageSize: e.rows,
      username,
      serviceClient,
    })
    fnDataRejected({
      pageNumber: e.page,
      pageSize: e.rows,
      username,
      serviceClient,
    })
  }

  const onSort = (e: DataTablePageEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)


    setFilterData({
      sortField: e.sortField,
      sortOrder: finalOrder,
      username,
      serviceClient,
    })
    fnDataRejected({
      sortField: e.sortField,
      sortOrder: finalOrder,
      username,
      serviceClient,
    })


  }

  const onSelectionChange = useCallback((values: any[]) => {
    setSelectedValues(values)
  }, [])




  const revertCommisionFn = () => {
    if (isSelected) {
      const newArray = selectedValues.map((item: CommissionElement) => {
        const state = item.authorization.authorizationState
        const stateToCompare = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        if (stateToCompare !== AuthorizationStatus.APPROVED) {
          return {
            id: item.commission.id,
            state: 'pending'
          }
        }
      })
      revertCommission(newArray)
    }
  }



  const getHeadersButtons = () => (
    <>

      {permissions.canWrite && isSelected && (
        <UfinetButton
          className="me-1"
          icon={faArrowsRotate}
          onClick={isAnyApproved ? showApprovalModal : showRevertModal}
          content={Translation('BUTTON.REVERT')}
        />
      )}

      {permissions.canWrite && (
        <UfinetButton
          isDisabled={isLoadingDownloadFile}

          icon={faDownload}
          onClick={() => downloadFile(filterData)}
          content={Translation('BUTTON.DOWNLOAD')}
        />
      )}
    </>
  )

  const filterButtons = () => {
    return (
      <>
        <MultiSelectFiltersHeaders
          // kamFilter={false}

          onSelectAll={onSelectAll}
          selectAllClient={selectAllClient}
          selectAllKam={selectAllKam}
          multiSelectClient={multiSelectClient}
          multiSelectKam={multiSelectKam}
          clientsSelect={clientsSelect}
          kamsSelect={kamsSelect}
          onChangeHandle={handleMultiSelect}
          isLoadingDataAuthorizator={isLoadingDataAuthorizator}
          isLoadingDataClients={isLoadingDataClients}
        />
      </>
    )

  }



  return (
    <>
      <UfinetBox>
        {isError && showToastErrMessage()}
        {isErrorDownloadFile && showToastDownloadErrMessage()}

        <UfinetSectionBox>
          <Table
            filterButtons={filterButtons()}
            dataKey="id"
            selectedValues={selectedValues}
            onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
            headerButtons={getHeadersButtons()}
            enableSearchFilter={false}
            // onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
            cols={colsRejectedPage}
            content={dataAuthRejected ? dataAuthRejected?.data?.commissions?.map((item: CommissionElement) => ({
              ...item,
              username: item.user.username,
              idService: item.service.idService,
              saleMonth: item.service.saleMonth,
              commissionType: item.commission.commissionType,
              exchangeRate: item.commission.exchangeRate,
              authorizationState: item.authorization.authorizationState,
              id: item.commission.id,
              totalAmount: item.commission.totalAmount,
              backlog: item.service.backlog,
              percentageCommission: item.commission.percentageCommission,
              baseAmount: item.commission.baseAmount,
              achievementFactor: item.commission.achievementFactor,
              paymentYear: item.commission.paymentYear,
              commissionTypePercentage: item.commission.commissionTypePercentage,
              rejectedDate: item.authorization.rejectedDate,
              saleYear: item.service.saleYear,
              idServiceCRM: item.service.idServiceCRM,
              serviceName: item.service.serviceName,
              serviceClient: item.service.serviceClient,
              authorizationReason: item.authorization.authorizationReason,
              totalAmountUSD: item.commission.totalAmountUSD,
              baseAmountUSD: item.commission.baseAmountUSD,
              additionalInfo: item.commission.additionalInfo,
            })) : []}
            actionBodyTemplate={<></>}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 250]}
            emptyMessage={isLoadingAuthDataRejected || isLoadingRevertCommission ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingAuthDataRejected || isLoadingRevertCommission,
                onFilter,
                onPage,
                onSort,
              }
            }
          />

          <UfinetModal
            size="lg"
            show={revertModal}
            handleClose={hideRevertModal}
            title={translate('APPROVED.MESSAGE2')}
          >
            <RevertBodyModal
              hideRevertModal={hideRevertModal}
              revertCommisionFn={revertCommisionFn}
              isLoadingRevertCommission={isLoadingRevertCommission}
            />

          </UfinetModal>

          <UfinetModal
            size="lg"
            show={containApprovalModal}
            handleClose={hideApprovalModal}
            title={translate('WARNING')}
          >
            <WarningModal hideApprovalModal={hideApprovalModal} />
          </UfinetModal>


        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}

export { RejectedAuthorizationPage }

